import React, { ReactElement } from "react";
import ReactPlayer from "react-player/lazy";
import { ThemeProvider } from "styled-components";

import Icon from "components/Icon";
import Image from "components/Image";
import { useBreakpoint } from "hooks";
import { A11y } from "style/components/A11Y";
import generateSrcSet, { commonSourceSets } from "style/generateSrcSet";

import {
    VideoWrapper,
    VideoContainer,
    ButtonWrapper,
    Title,
    TitleWrapper,
    PlayIcon,
    PlayIconWrapper,
    Overlay,
} from "./Video.styled";
import VideoProps, { PlayButtonProps } from "./VideoProps";

const Play = ({ image, title, l18nPlayVideo }: PlayButtonProps): ReactElement => {

    if (image && image.url)
        image.srcSet = generateSrcSet({
            image: image.url,
            focalPoint: image.focalPoint,
            content: commonSourceSets.large,
            maxWidth: image.uploadedWidth,
            maxHeight: image.uploadedHeight,
            format: "jpg",
        });

    let iconSize: string;
    switch (useBreakpoint()) {
        case "XL":
            iconSize = "70";
            break;
        case "L":
            iconSize = "56";
            break;
        case "M":
            iconSize = "56";
            break;
        default:
            iconSize = "28";
    }

    return (
        <ButtonWrapper role="button" tabIndex={0}>
            {title && (
                <TitleWrapper>
                    <Title dangerouslySetInnerHTML={{ __html: title }} />
                </TitleWrapper>
            )}
            <PlayIconWrapper>
                <PlayIcon>
                    <Icon
                        key={`Icon-play${iconSize || ""}`}
                        icon={`play${iconSize}`}
                    />
                    <A11y>{l18nPlayVideo}</A11y>
                </PlayIcon>
            </PlayIconWrapper>
            {image ? (
                <Image
                    {...image}
                    overlay={image.overlay || "UpLeft"}
                    cover={true}
                    caption={undefined}
                    darken={true}
                />
            ) : (
                <Overlay />
            )}
        </ButtonWrapper>
    );
};

const Video = ({
    identifier,
    url,
    title,
    image,
    className,
    anchorName,
    playing = false,
    size = "large",
    l18nPlayVideo,
}: VideoProps): ReactElement => {
    if (url?.includes("youtube.com"))
        url = url.replace("youtube.com", "youtube-nocookie.com");

    if (className?.includes("embedded")) size = "small";

    return (
        <ThemeProvider theme={{ hasImage: !!image, size: size }}>
            <VideoWrapper
                className={className}
                id={anchorName}
                key={identifier || `VideoContent`}
            >
                <ReactPlayer
                    url={url}
                    title={title}
                    light={!playing} // Show overlay when playing isnt set
                    playing={true}
                    playIcon={<Play image={image} title={title} l18nPlayVideo={l18nPlayVideo} />}
                    wrapper={VideoContainer}
                    width="100%"
                    height="100%"
                    controls={true}
                    previewTabIndex={null}
                />
            </VideoWrapper>
        </ThemeProvider>
    );
};

export default React.memo(Video);
